.customer_main {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    .row_customer {
        width: 100%;
        height: calc(100% - 68px);
        padding-top: 1em;
    }
}