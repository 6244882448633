.categorias_main {
    h1 {
        font-weight: 700;
        display: inline;
        color: @link-color;
    }
    .ant-col {
        padding: 0 10px;
        a {
            .ant-card {
                transition: all ease-in-out .4s;
                border-radius: 0;
                border: none;
                background: #eeeeee ;
                height: 200px;
                margin-top: 10px;
                .ant-card-body {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    h5 {
                        transition: all ease-in-out .4s;
                        font-weight: 700;
                        color: #424242;
                        font-size: 1.5rem;
                        border-bottom: 2px solid #424242;
                        border-bottom-width: 30%;
                        position: absolute;
                        top: 150%;
                        left: 20px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        min-width: 100%;
                        min-height: 200px;
                        position: absolute;
                        right: 0;
                        top: 0;
                        opacity: 0.35;
                        // object-fit: co;
                        
                    }
                }
            }
            &:hover {
                .ant-card {
                    background-color: @primary-color;
                    .ant-card-body {
                        h5 {
                            color: #fff;
                            border-bottom-color: #fff;
                        }
                    }
                }
            }
        }
    }
}