.publish_main {
    padding: 0 20px;
    .inputsCode {
        width: 100%;
    }
}

.cardProduct {
    margin-top: 10px;
    .ant-card-body {
        padding: 8px;
        .ant-col {
            display: flex;
                align-items: center;
            a {
                display: flex;
                align-items: center;
                .description_card {
                    margin-left: 20px;
                    p {
                        margin: 0;
                    }
                }
            }
        }
    }
}