.catalogo_main {
    padding: 3%;
.ant-col {
    padding: 5px;
    .card_product {
        border-radius: 0;
        .ant-card-body {
            position: relative;
            .tag_estado {
                position: absolute;
                top: 5;
                left: 5;
                background: @primary-color;
                color: #fff;
                border: none;
                text-transform: uppercase;
                border-radius: 0;
            }
            h4 {
                font-weight: 600;
                font-size: 1rem;
                margin-top: 40px;
            }
            p {
                font-size: small;
            }
            a {
                transition: all ease-in-out .4s;
                &:hover{
                    color: @primary-color;
                }
            }
            .container_img {
                width: 100%;
                height: 200px;
                text-align: center;
                img {
                    max-width: 100%;
                    width: auto;
                    height: auto;
                    max-height: 200px;

                }
            }
        }
    }
}
}

.catalogo_container {
    width: 100%;
    height: 100vh;
    padding: 0 3em;
    .sidebar_products {
        padding-top: 1em;
        .select_country {
            width: 100%;
    
        }
    }

    .products_container {
        padding: 1em;
        width: 100%;
        height: 100%;
        .ant-card-body {
            padding: 10px;
        }
        a {
            width: 100%;
            height: 100%;
            position: relative;
            .container_img {
                width: 100%;
                height: 200px;
                overflow: hidden;
                vertical-align: middle;
                img {
                    width: 100%;
                    min-height: 200px;
                    object-fit: fill;
                }
            }
            .title_product_c {
                height: 57px;
                overflow: hidden;
                h4 {
                    font-weight: 600;
                    font-size: 1rem;
                    margin-top: 10px;
                }
            }

            .description_container {
                height: 42px;
                p {
                    font-size: small;
                }
            }

            a {
                transition: all ease-in-out .4s;
                &:hover{
                    color: @primary-color;
                }
            }
            .tag_estado {
                position: absolute;
                top: 5;
                left: 5;
                background: @primary-color;
                color: #fff;
                border: none;
                text-transform: uppercase;
                border-radius: 0;
            }
        }
    }

}