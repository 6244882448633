.modal_login {
    .ant-modal-content {
        border-radius: 10px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 50px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 50px;
    }
    
    .title_login {
        text-align: center;
        font-weight: 600;
    }
    .input_login {
        font-size: 16px;
        height: 50px;
        padding-left: 12px;
    }

    .select_login {
        .ant-select-selector{
            font-size: 16px;
            height: 50px;
            padding-left: 12px;
        }

    }

    .btn_login {
        width: 100%;
        height: 50px;
        font-size: 16px;
    }
    .text_login {
        padding: 5px 0;
        text-align: center;
        display: block;
        font-size: 16px;
    }
    .pd_top {
        margin-top: 10px;
    }
    .link_login {
        text-decoration: underline;
        transition: all ease-in-out 400ms;
        color: @primary-color;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
    }
}

.link_text {
    transition: all ease-in-out 400ms;
    cursor: pointer;
    &hover {
        color: @primary-color;
    }
}

.text_term {
    color: @primary-color;
    cursor: pointer;
}