@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './variables.less';

//components
@import './component/menu.less';
@import './component/footer.less';
@import './component/loginSocial.less';


//pages
@import './pages/home.less';
@import './pages/login.less';
@import './pages/catalogo.less';
@import './pages/product.less';
@import "./pages/home/modals.less";
@import './pages/customer/customer.less';

//panel
@import './pages/customer/publish.less';
@import './pages/customer/edit.less';
@import './pages/customer/chat.less';


//config

body {
    font-family: @comforta;   
}