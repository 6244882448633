.my-facebook-button-class, .my-google-button-class {
    margin-top: 10px;
    transition: all ease-in-out 400ms;
    border: none;
    border-radius: 4px;
    background-color: #fff;
    display: block;
    width: 100%;
    height: 50px;
    border: 2px solid @primary-color;
    cursor: pointer;
    color: #3b5998;
    font-size: 16px;
    font-weight: 500;
    span {
        padding-left: 10px;
    }
    &:hover {
        transform: translateY(-5px);
    }
}

.my-google-button-class {
    margin-top: 10px;
    transition: all ease-in-out 400ms;
    border: none;
    border-radius: 4px;
    background-color: #fff;
    display: block;
    width: 100%;
    height: 50px;
    border: 2px solid @primary-color;
    cursor: pointer;
    color: #db4a39;
    font-size: 16px;
    font-weight: 500;
    span {
        padding-left: 10px;
    }
    &:hover {
        transform: translateY(-5px);
    }
}

// .my-google-button-class {
//     border-radius: 0;
//     border: none;
//     cursor: pointer;
//     background-color: #db4a39;
//     color: #fff;
//     width: 50px;
//     height: 50px;
//     font-size: 25px;
//     &:focus {
//         outline: none;
//     }
// }