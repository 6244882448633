.what_main {
    // margin-top: 5%;
    h1 {
        text-align: center;
        font-weight: 700;
        color: @link-color;
    }
    h3 {
        font-weight: 600;
        font-size: 1.5rem;
        color: @primary-color;
    }
    .figura {
        margin-top: 20%;
        margin-left: 15%;
        width: 70%;
    }
    .linetime {
        margin-top: 20%;
    }
}