.footer_ {
    text-align: center;
    width: 100%;
    background-position: top center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding-top: 120px;
    margin-top: 5%;
    padding-bottom: 20px;
    .a_footer {
        margin: 0 5px;
        img {
            width: 25px;
        }
    }
    h1 {
        color: #fff;
        font-weight: 700;
        font-size: 2.8rem;
    }
    div{
        color: #fff;
        a {
            color: #fff;
        }
    }
}