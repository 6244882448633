.login_main {
    padding: 1em;
    width: 100%;
    // padding: 5%;
    // height: 100vh;
    min-height: 100vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
    // position: relative;
    h1 {
        text-align: center;
        color: @primary-color;
        font-weight: 700;
    }
    p{
        text-align: center;
        margin-bottom: 50px;
    }
    .footer_auth {
        // position: absolute;
        width: 100%;
        text-align: center;
        bottom: 30px;
        left: 0;
        a {
            transition: all ease-in-out .4s;
            &:hover {
                color: @primary-color;
            }
        }
    }
}