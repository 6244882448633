.product_main {
    margin-top: 5%;
    width: 100%;
    h1 {
        font-weight: 700;
        color: @link-color;
    }
    .card_product {
        border-radius: 0;
        .ant-card-body {
            position: relative;
            .tag_estado {
                position: absolute;
                top: 5;
                left: 5;
                background: @primary-color;
                color: #fff;
                border: none;
                text-transform: uppercase;
                border-radius: 0;
                z-index: 99;
            }
            h4 {
                font-weight: 600;
                font-size: 1rem;
                line-height: 1rem;
                margin-top: 40px;
            }
            p {
                font-size: small;
            }
            a {
                transition: all ease-in-out .4s;
                color: @primary-color;
                &:hover{
                    color: @primary-color;
                }
            }
            .container_img {
                width: 100%;
                height: 260px;
                img {
                    max-width: 100%;
                    width: auto;
                    height: auto;
                    max-height: 260px;
                }
            }

        }
    }
}