.chat_main{
    padding: 30px;
    .chat_card {
        width: 100%;
        margin-top: 10px;
        border-radius: 0 !important;
        .ant-card-head {
            background: @primary-color;
            color: #fff;
        }
        .ant-card-body {
            .col_chat {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .zone_messages {
                    min-width: 100%;
                    padding: 20px;
                    overflow: auto;
                    // overflow-x: hidden;
                    max-height: 55vh;
                    .container_right {
                        width: 100%;
                        text-align: right;
                        margin-top: 8px;
                        .messageRight{
                            margin: 0;
                            background: @link-color;
                            border: none;
                            display: inline-block;
                            color: white;
                            padding: 5px;
                            border-radius: 5px;
                        }
                    }
                    .container_left {
                        width: 100%;
                        text-align: left;
                        margin-top: 8px;
                        .mesageTag {
                            background: @primary-color;
                            border: none;
                            border-radius: 5px;
                            margin: 0;
                            color: #fff !important;
                            display: inline-block;
                            color: rgb(146, 134, 134);
                            padding: 5px;
                            text-align: left;
                        }
                    }
                }
                ::-webkit-scrollbar {
                    width: 10px;
                    height: 10px;
                    background: #eeeeee;
                }
                ::-webkit-scrollbar-thumb {
                    border-radius: 5px;
                    background: @primary-color;
                }
                .zone_input {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 10%;
                }
            }
        }
    }
    .inputfile {
        position: absolute;
        visibility: hidden;
        z-index: 999;
    }
}

// new chat
.chat_node {
    width: 100%;
    height: calc(100vh - 60px);
    .header_chat {
        border-radius: 4px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        height: 60px;
        background-color: @primary-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        h5 {
            margin-bottom: 0;
            color: #333;
        }
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333;
        }
    }
    .zone_chat {
        display: flex;
        width: 100%;
        height: 100%;
        .chat_list {
            width:320px;
            // height: 300px;
            border-right: 1px solid #f0f2f5;
        }
        .chat_container {
            width: 100%;
            height: 100%;
            position: relative;
            // display: flex;
            // flex-direction: column;
            // justify-content: space-between;
            .wrapper_msg {
                height: 90%;
                overflow: hidden;
                .messages_box {
                    overflow: auto;
                    padding: 5px 1em;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column-reverse;
                    .message_item_right {
                        padding-bottom: 12px;
                        width: 70%;
                        margin-left: auto;
                        text-align: right;
                        .message_cont {
                            
                            display: inline-block;
                            background: @primary-color;
                            border-radius: 4px;
                            padding: 4px;
                            span {
                                color: #fff;
                                font-size: 16px;
                            }
                        }
                        span {
                            display: block;
                        }
     
                    }
                    .message_item_left {
                        padding-bottom: 12px;
                        width: 100%;
                        margin-left: auto;
                        text-align: left;
                        .message_cont {
                            
                            display: inline-block;
                            background: @link-color;
                            border-radius: 4px;
                            padding: 4px;
                            span {
                                color: #fff;
                                font-size: 16px;
                            }
                        }
                        span {
                            display: block;
                        }
                    }
                }
            }

            .box_input_chat {
                // position: absolute;
                // bottom: 1em;
                // left: 0;
                width: 100%;
                display: flex;
                align-items: center;
                input {
                    width: 100%;
                }
            }
        }
    }

}

// item chat list
.item_chat_list {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    transition: all ease-in-out 400ms;
    cursor: pointer;
    .name_chat_list_container {
        padding-left: 10px;
        span {
            color: #333;
            display: block;
            &:nth-child(1) {
                font-weight: 600;
            }
            &:nth-child(2) {
                font-size: 12px;
                font-weight: 500;
            }
        }
    }
    &:hover {
        background: @primary-color;
        border-radius: 4px;  
    }
}

.item_chat_list.active {
    background: @primary-color;
    border-radius: 4px;
}