.home_main {
    .portada {
        background-color: #fafafa;
        width: 100%;
        height: calc(100vh - 1.5%);
        padding: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        h2, h1, h4 {
            text-transform: uppercase;
            color: #424242;
            margin: 0;
        }
        h1 {
            font-weight: 700;
            font-size: 2.5rem;
            span {
                color: @primary-color;
            }
        }
        a {
            color: @primary-color;
            margin-top: 40px;
            font-size: 1.4rem;
            border-bottom: 2px solid @primary-color;
            border-width: 30%;
        }
    }
}

.terms_ {
    padding: 1em 5%;
    .terms_title {
        text-transform: capitalize,
    }
}

// categorias
@import './home/categories.less';
@import './home/products.less';
@import './home/what.less';
@import './home/steps.less';
